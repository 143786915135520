import { template as template_433625a48e1f4416a409869f4d09d6cd } from "@ember/template-compiler";
const SidebarSectionMessage = template_433625a48e1f4416a409869f4d09d6cd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
