import { template as template_606d63b1bd104141b5051ba60102104a } from "@ember/template-compiler";
const FKText = template_606d63b1bd104141b5051ba60102104a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
